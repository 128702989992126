@import '~antd/lib/style/themes/default.less';
@import './validation.less';
@import './page.less';
@import './menu.less';
@import './font.less';
@import './layout.less';
@import './_tab.less';
@import './_ul.less';

@homepage: inps-page;
.@{homepage}-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  .@{homepage} {
    height: auto;
    max-width: 100%;
    position: relative;
    margin: auto;
    will-change: transform;
  }
  .title-wrapper > h1,
  > h1 {
    font-size: 32px;
    color: @text-color;
    margin-bottom: 16px;
  }
  .title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
  }
}

.@{homepage} {
  padding: 128px 24px;
}

.@{homepage}-none {
  padding: 15px 24px !important;
}

.@{homepage}-none1 {
  padding: 5px 24px !important;
}
.@{homepage}-none21 {
  padding: 5px 24px !important;
  background-color: #fafafa;
}
.@{homepage}-padding {
  padding: 0 24px !important;
}

@media screen and (max-width: 767px) {
  .@{homepage}-wrapper {
    .@{homepage} {
      padding: 56px 24px;
      > h1 {
        font-size: 24px;
        margin: 0 auto 32px;
        &.title-h1 {
          margin-bottom: 8px;
        }
      }
      > p {
        margin-bottom: 32px;
      }
    }
  }
}

.css-list
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-list-items
  .ant-list-item {
  padding: unset;
}

@primary-color: #293642;