@font-face {
  font-family: 'Roboto-bold';
  src: url('../font/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../font/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../font/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../font/Roboto-Regular.ttf');
}
