@afspana: afspana-page;
.@{afspana}-wrapper {
  background-color: #ffffff;
  min-height: 100vh;
  .@{afspana} {
    > p {
      text-align: center;
    }
    &-img-wrapper {
      margin: 0 auto;
      left: 0;
      right: 0;
      .block {
        margin: auto;
        .afspana-page-block-content {
          display: block;
          background: #fff;
          border-radius: 4px;
          // padding: 10px;
          text-align: center;
          position: relative;
          overflow: hidden;
          .page-pro();
          border: none;
          transition: box-shadow 0.3s @ease-out, transform 0.3s @ease-out;
          & > span {
            width: 100%;
            height: 160px;
            display: block;
            background: @line-color;
            padding: 5%;
          }
          & p {
            width: 100%;
            line-height: 30px;
          }
          & .box-shape {
            display: none;
          }
          & .box-shape-hover {
            background-color: #00000000;
            transition: all 0.3s;
          }
          &:hover {
            & p {
              bottom: 0;
            }
            & .box-shape {
              display: block;
            }
            & .box-shape-hover {
              background-color: #00000050;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{afspana}-wrapper {
    overflow: hidden;
    .@{afspana} {
      ul {
        li {
          span {
            //height: 168px;
          }
          p {
            position: relative;
            bottom: 0;
          }
        }
      }
    }
  }
}

.react-multi-carousel-list {
  padding: 20px 0;
}

.@{afspana}-ul{
  margin: unset !important;
}

.page-a{
  color: #ffffff !important;
}

.block .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
  border-bottom: unset;
}