.css-tab0 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #ff5757;
}

.css-tab1 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #613bff;
}

.css-tab2 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #77d939;
}

.css-tab3 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #ff96e3;
}

.css-tab4 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #1a8dff;
}

.css-tab5 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #b90000;
}

.css-tab6 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #ff6c00;
}

.css-tab7 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #ffc400;
}

.css-tab8 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #59d100;
}
.css-tab9 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #2d45ac;
}

.css-tab10 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #7b5cff;
}

.css-tab11 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #5bce12;
}
