@banner: banner-user;
.@{banner},
.banner-anim {
  height: 500px;
}

.banner-anim-elem.@{banner}-elem {
  color: #fff;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.banner-thumb-bottom {
  .banner-anim-elem {
    height: 560px;
  }
  .banner-anim-thumb {
    background: #999;
  }
}

.banner-anim-elem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.text-wrapper {
  color: #fff;
  text-align: center;
  margin-top: 100px;
  h1,
  h2 {
    color: #fff;
    font-weight: lighter;
  }
  h1 {
    font-size: 42px;
    margin-bottom: 8px;
    line-height: 42px;
    font-family: 'PingFang SC,Helvetica Neue,Helvetica,Hiragino Sans GB,Microsoft YaHei,\\5FAE\8F6F\96C5\9ED1,Arial,sans-serif';
  }
  h2 {
    font-size: 12px;
    margin-bottom: 12px;
  }
  p {
    line-height: 24px;
    margin-bottom: 16px;
  }
}

.icon-wrapper {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  > div {
    position: absolute;
    &:nth-child(1) {
      left: 10%;
      top: 20%;
    }
    &:nth-child(2) {
      left: 40%;
      top: 70%;
    }
    &:nth-child(3) {
      right: -20px;
      bottom: 80px;
    }
  }
}

@primary-color: #293642;