.css-list
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-list-items
  .css-ul0
  .ant-list-item-meta
  .ant-list-item-meta-avatar {
  width: 50px;
  text-align: end;
  color: #5bce12;
}
